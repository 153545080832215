import { createApp } from "vue";
import VueAnimXyz from "@animxyz/vue3";
import "@animxyz/core";
import { createVfm } from "vue-final-modal";
import "vue-final-modal/style.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./plugins/vue-lang-router";
import "./scss/main.scss";

const vfm = createVfm();

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueAnimXyz)
  .use(vfm)
  .mount("#app");
