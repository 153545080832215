<template>
  <div ref="el" class="row-extendable" :class="classes">
    <slot :width="width" :extended="extended" />
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useResizeObserver, templateRef } from "@vueuse/core";

export default {
  name: "ExtendableRow",

  props: {
    minWidth: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const el = templateRef("el", null);
    const width = ref(Infinity);
    const extended = ref(false);
    watch(width, (width, oldWidth) => {
      // Threshold to avoid infinite width changes when row is near breakpoint
      const threshold = 20;
      if (width > oldWidth && width - oldWidth < threshold) {
        return;
      }
      extended.value = width <= parseInt(props.minWidth);
    });

    const classes = computed(() => {
      return {
        "scrollable-container": extended.value,
        extended: extended.value,
      };
    });

    useResizeObserver(el, (entries) => {
      if (!el.value) return;
      const entry = entries[0];
      width.value = entry.contentRect.width;
    });

    return {
      extended,
      width,
      classes,
    };
  },
};
</script>

<style lang="scss">
.row-extendable {
  width: 100%;

  &.extended {
    width: calc(100% + 8px);
  }
}

.scrollable-container {
  /* Make this scrollable when needed */
  overflow-x: auto;
  /* We don't want vertical scrolling */
  overflow-y: hidden;
  /* Make an auto-hiding scroller for IE */
  -ms-overflow-style: -ms-autohiding-scrollbar;
  /* For WebKit implementations, provide inertia scrolling */
  -webkit-overflow-scrolling: touch;
  /* We don't want internal inline elements to wrap */
  white-space: nowrap;
  /* Remove the default scrollbar for WebKit implementations */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Remove the default scrollbar for Firefox 64+ */
  scrollbar-width: none;

  > * {
    white-space: normal;
  }
}
</style>
