<template>
  <div
    class="d-md-none filters-widget position-sticky"
    style="top: 90px; z-index: 1"
  >
    <filters />
  </div>
  <div class="container pb-5">
    <div class="row">
      <div class="d-none d-md-block col-auto">
        <filters />
      </div>

      <div class="col flex-grow-1">
        <div class="row">
          <div
            v-for="product in products"
            :key="product.sku"
            class="col-12 col-md-6 col-lg-4 g-4"
          >
            <card-element
              :id="product.id"
              :title_ro="product.title_ro"
              :title_ru="product.title_ru"
              :price="product.price"
              :discount-price="product.discountPrice"
              :img="product.main_img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardElement from "./CardElement";
import Filters from "./Filters";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "CardElementLayout",
  components: { Filters, CardElement },
  setup() {
    const store = useStore();

    const products = computed(() => {
      return store.getters.paginatedProducts;
    });

    store.dispatch("fetchProducts");

    return {
      products,
    };
  },
};
</script>

<style lang="scss" scoped>
.filters-widget {
  display: flex;
  justify-content: center;
  width: 100%;
  top: 25px;
  z-index: 1;
  background-color: #fff;

  > * {
    width: 100%;
    max-width: 300px;
  }
}
</style>
