<template>
  <base-layout>
    <main-image />
    <card-element-layout />
    <pagination />
  </base-layout>
</template>

<script>
import CardElementLayout from "../components/CardElementLayout";
import MainImage from "../components/MainImage";
import BaseLayout from "../layouts/baseof";
import Pagination from "../components/Pagination";

export default {
  name: "index",
  components: {
    Pagination,
    BaseLayout,
    MainImage,
    CardElementLayout,
  },
};
</script>

<style scoped></style>
