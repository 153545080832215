<template>
  <extendable-row
    :min-width="`${breakpoint}px`"
    dragscroll
    class="items-container"
  >
    <template #default="{ extended }">
      <component
        :is="tag"
        class="items"
        :class="{ 'items--extended': extended }"
        :style="styles"
      >
        <slot :extended="extended" />
      </component>
    </template>
  </extendable-row>
</template>

<script>
import { computed, defineComponent } from "vue";

import ExtendableRow from "./ExtendableRow.vue";

export default defineComponent({
  name: "ScrollableItems",
  components: { ExtendableRow },
  props: {
    tag: {
      type: String,
      default: "div",
    },
    breakpoint: {
      type: Number,
      default: 768,
    },
    // Min width before overflow
    minWidth: {
      type: Number,
      default: 150,
    },
  },
  setup(props) {
    const styles = computed(() => {
      return {
        "--min-width": `${props.minWidth}px`,
      };
    });

    return {
      styles,
    };
  },
});
</script>

<style lang="scss" scoped>
.items-container {
  $breakpoint: 768px;

  .items {
    display: inline-grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    padding-right: 8px;
    padding-bottom: 3px;
    margin: 0 auto;

    &:not(.items--extended) {
      padding-right: 0;
      //display: grid;
      //grid-template-columns: repeat(auto-fit, minmax(var(--min-width), 1fr));
      //padding-right: 0;
    }
  }
}
</style>
