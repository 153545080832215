<template>
  <span class="c-checkbox">
    <input type="checkbox" v-model="checked" :id="uid" />
    <label :for="uid">{{ label }}</label>
  </span>
</template>

<script>
import { computed, defineComponent } from "vue";
import { nanoid } from "nanoid";

export default defineComponent({
  name: "CustomCheckbox",
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const uid = `checkbox-${nanoid(11)}`;

    const checked = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    return {
      uid,
      checked,
    };
  },
});
</script>

<style lang="scss" scoped>
.c-checkbox {
  display: flex;
  white-space: nowrap;

  input[type="checkbox"] {
    flex: 0 0 auto;
    -webkit-appearance: none;
    appearance: none;
    background: none; /* iOS fix */
    box-shadow: inset 0 0 0 0.125rem #888;
    height: 1.125rem;
    width: 1.125rem;
    display: inline-block;
    border-radius: 0.125rem;
    position: relative;
    cursor: pointer;
    // margin-right: 0.5rem;

    &:active {
      box-shadow: inset 0 0 0 0.125rem $light-violet;
    }

    &:checked {
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0,0H24V24H0Z" fill="none"/><path d="M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3ZM10,17,5,12l1.41-1.41L10,14.17l7.59-7.59L19,8Z" fill="%23c891e3"/></svg>');
      background-position: center;
      box-shadow: none;

      &:active {
        opacity: 0.9;
      }
    }

    // add nice hover effect input
    &::before {
      content: "";
      display: inline-block;
      height: 100%;
      width: 100%;
      background-color: transparentize(#2962ff, 1);
      position: absolute;
      border-radius: 50%;
      transform: scale(0.5);
      transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
      will-change: transform;
    }

    &:hover::before {
      background-color: transparentize(#2962ff, 0.9);
      transform: scale(2.25);
    }
  }

  label {
    position: relative;
    top: -3px;
    white-space: normal;
    line-height: 1.3;
    padding: 0.1em 0.7em;
    border-radius: 15px;
    // box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
}
</style>
