<template>
  <button v-if="tag === 'button'" class="custom-btn">{{ label }}</button>
  <localized-link v-else-if="tag === 'a'" v-bind="$attrs" class="custom-btn">{{
    label
  }}</localized-link>
</template>

<script>
import LocalizedLink from "../plugins/vue-lang-router/components/LocalizedLink";
export default {
  name: "CustomButton",
  components: { LocalizedLink },
  props: {
    label: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      default: "button",
    },
  },
};
</script>

<style lang="scss">
.custom-btn {
  color: inherit;
  display: inline-block;
  border: none;
  background-color: transparent;
  position: relative;
  transition: color 0.25s;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: $primary-color;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover {
    color: $primary-color;

    &:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}

a.custom-btn {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
</style>
