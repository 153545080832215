<template>
  <div
    class="hero-img pb-4"
    :style="{
      backgroundImage,
    }"
  ></div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
const store = useStore();

const backgroundImage = computed(() => {
  const img = store.state.siteData?.hero_image;
  if (!img) return;

  return `url(${process.env.VUE_APP_S3_ASSETS_URL}/${img}/main-image.jpg?quality=80)`;
});

defineExpose({
  backgroundImage,
});
</script>

<style lang="scss" scoped>
.hero-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 400px;
  padding-top: 8px;
}
</style>
