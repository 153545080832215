<template>
  <div class="nav-wrapper overflow-hidden ps-2 pe-2">
    <div class="pt-2 pb-2" :class="{ container: isLargeScreen }">
      <scrollable-items class="nav">
        <div
          v-for="category in categories"
          :key="category.id"
          class="list-inline-item"
        >
          <a
            class="nav-link"
            :class="{
              active: $route.query.category === category.id.toString(),
            }"
            :href="
              $router.resolve({
                name: 'home',
                query: { category: category.id },
              }).fullPath
            "
            @click.prevent="
              $router.push({
                name: 'home',
                query: { category: category.id },
              })
            "
            >{{ category[`title_${$i18n.locale}`] }}</a
          >
        </div>
      </scrollable-items>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed, watch } from "vue";
import { useRouteQuery } from "@vueuse/router";
import ScrollableItems from "./layout/ScrollableItems.vue";
import { useMediaQuery } from "@vueuse/core";

const store = useStore();
const categoryId = useRouteQuery("category");
watch(
  categoryId,
  (id) => {
    // reset product types
    store.commit(
      "setProductTypes",
      store.state.productTypes.map((item) => {
        item.active = false;
        return item;
      })
    );
    store.commit("setActiveCategory", +id);
  },
  {
    immediate: true,
  }
);

const categories = computed(() => store.state.categories);

const isLargeScreen = useMediaQuery("(min-width: 1024px)");
</script>

<style lang="scss" scoped>
.nav-wrapper {
  background-color: white;
  z-index: 2;
  position: sticky;
  top: 60px;

  @media (min-width: map-get($grid-breakpoints, md)) {
    top: 0;
  }
}

.nav {
  font-family: $font-family-secondary;
  font-size: 1.2em;
}

.nav-link {
  padding: 0;
  white-space: nowrap;
  color: #666;

  &.active,
  &:hover {
    color: $primary-color !important;
  }
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
</style>
