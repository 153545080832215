<template>
  <div class="copyright text-center py-1">
    <p class="p-secondary">
      <span>©</span> <span class="copyright__title text-deco">Brillio</span>
      <span>{{ year }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "MainFooter",
  components: {},
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
.copyright {
  p {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 15px 0;
  }

  &__title {
    font-size: 2.4em;
    margin-left: 5px;
    margin-right: 8px;
    transform: translateY(-2px);
  }
}
</style>
