<template>
  <div class="title-line d-none d-lg-block"></div>

  <div class="header-top d-lg-none py-2">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto">
          <change-lang class="m-0" />
        </div>
        <div class="col-auto">
          <div class="row justify-content-end align-items-center text-bold">
            <div class="col-auto">
              <a
                href="tel:069-588-422"
                class="custom-link d-flex align-items-center"
                ><i class="lni lni-phone"></i>&nbsp;069-588-422</a
              >
            </div>
            <div class="col-auto">
              <a
                href="tel:069-135-253"
                class="custom-link d-flex align-items-center"
                >069-135-253</a
              >
              <!--                <header-socials />-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid container-md">
    <div
      class="row justify-content-center justify-content-lg-between align-items-center"
    >
      <div class="col-auto d-none d-lg-block col-md-4">
        <change-lang class="m-0" />
      </div>
      <div class="col-auto col-md-4 text-center title-content">
        <h1 class="m-0 text-deco main-title" id="top">
          <router-link
            to="/"
            class="unstyled-link"
            :style="{ fontSize: fonstSize }"
            >Brillio</router-link
          >
        </h1>
      </div>
      <div class="col-auto d-none d-lg-block col-md-4">
        <div class="row justify-content-end align-items-center text-bold">
          <div class="col-auto">
            <a
              href="tel:069-588-422"
              class="custom-link d-flex align-items-center"
              ><i class="lni lni-phone"></i>&nbsp;069-588-422</a
            >
          </div>
          <div class="col-auto">
            <a
              href="tel:069-135-253"
              class="custom-link d-flex align-items-center"
              >069-135-253</a
            >
            <!--              <header-socials />-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ChangeLang from "./ChangeLang";
import { useWindowScroll } from "@vueuse/core";
import { computed } from "vue";

const { y } = useWindowScroll();
const fonstSize = computed(() => {
  const maxScrollHeight = 100;
  if (y.value > maxScrollHeight) return 2.3;

  const startFontSize = 3.7; // in rem
  const endFontSize = 2.3;
  const distance = startFontSize - endFontSize;

  const scrollPercent = Math.min(y.value, maxScrollHeight) / maxScrollHeight;
  return (startFontSize - distance * scrollPercent).toString() + "rem";
});
</script>

<style lang="scss" scoped>
.main-title {
  font-size: 3.7em;
}

.header-top {
  background-color: $light-grey;

  @media (max-width: map-get($grid-breakpoints, md)) {
    + .container-fluid {
      background-color: #fff;
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }
}

.title-content {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-line {
  height: 8px;
  width: 100%;
  background: rgb(226, 207, 227);
  background: linear-gradient(
    113deg,
    rgba(226, 207, 227, 1) 21%,
    rgba(244, 244, 252, 1) 55%,
    rgba(228, 231, 241, 1) 92%
  );
}

i {
  font-size: 1.3rem;
}
</style>
