import { Directus } from "@directus/sdk";

const directus = new Directus("https://admin.brillio.md");

export function getSiteData() {
  return directus.items("site").readOne(1);
}

export function getCategories() {
  return directus.items("Product_Category").readByQuery();
}

export function getProductType() {
  return directus.items("Product_Type").readByQuery();
}

export function getProducts() {
  return directus.items("product").readByQuery({
    limit: 1000,
    fields: [
      "category.Product_Category_id.id",
      "discountPrice",
      "id",
      "main_img",
      "price",
      "product_type.Product_Type_id",
      "sku",
      "status",
      "title_ro",
      "title_ru",
    ],
    filter: {
      status: {
        _eq: "published",
      },
    },
  });
}

export function getSimilarProducts(productId, categoryId) {
  return directus.items("product").readByQuery({
    fields: [
      "category.Product_Category_id.id",
      "discountPrice",
      "id",
      "main_img",
      "price",
      "product_type.Product_Type_id",
      "sku",
      "status",
      "title_ro",
      "title_ru",
    ],
    filter: {
      status: {
        _eq: "published",
      },
      id: { _neq: productId },
      category: { Product_Category_id: { id: { _eq: categoryId } } },
    },
    limit: 6,
  });
}

export function getProduct(id) {
  return directus.items("product").readOne(id, {
    fields: [
      "availableSizes",
      "caratness",
      "category.Product_Category_id.id",
      "discountPrice",
      "form",
      "id",
      "images",
      "length",
      "main_img",
      "material_ro",
      "material_ru",
      "nb_stones",
      "price",
      "product_type.Product_Type_id",
      "sample",
      "sku",
      "status",
      "stones_ro",
      "stones_ru",
      "title_ro",
      "title_ru",
      "weight",
    ],
    filter: {
      status: {
        _eq: "published",
      },
    },
  });
}
