<template>
  <div class="address-container py-4 d-flex flex-column justify-content-center">
    <h4>{{ $t("location") }}</h4>
    <div class="address-info">
      <p class="mb-2">Chișinău, str.&nbsp;Tighina&nbsp;61</p>
      <div class="address-phone-wrapper text-bold">
        <a href="tel:069-588-422" class="custom-link">069-588-422</a><br />
        <a href="tel:069-135-253" class="custom-link">069-135-253</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddressInfo",
};
</script>

<style lang="scss" scoped>
.address-container {
  background-color: #f5f5f5;

  .address-info {
    font-size: 1.1em;
  }
}
</style>
