<template>
  <XyzTransition xyz="fade small-2 down-4 ease-out-back">
    <div v-if="btnVisible" class="back-to-top" @click.prevent="scrollToTop">
      <a href="#" class="back-to-top-content"
        ><i class="lni lni-chevron-up"></i
      ></a>
    </div>
  </XyzTransition>
</template>

<script>
import { useWindowScroll } from "@vueuse/core";
import VueScrollTo from "vue-scrollto";
import { computed } from "vue";

export default {
  name: "BackToTop",
  setup() {
    const { y } = useWindowScroll();

    const btnVisible = computed(() => y.value > 250);

    function scrollToTop() {
      VueScrollTo.scrollTo("#top", 200, { easing: "ease-in" });
    }

    return {
      btnVisible,
      scrollToTop,
    };
  },
};
</script>

<style lang="scss" scoped>
.back-to-top {
  z-index: 999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  background-color: $light-violet;
  border-radius: 50%;
  // box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 25px;
  right: 25px;
  transition: background-color 0.25s ease-out;

  .back-to-top-content {
    text-decoration: none;
    font-size: 1.2em;
    -webkit-font-smoothing: none;
    color: white;
  }

  &:hover {
    background-color: darken($light-violet, 5%);
    // box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4);
  }
}
</style>
