<template>
  <ul v-if="totalPages > 1" id="pagination" class="ps-0">
    <li v-if="pagination.currentPage > 1">
      <a href="#" @click.prevent="previous()"
        ><i class="lni lni-arrow-left"></i
      ></a>
    </li>
    <li v-for="i in totalPages" :key="i">
      <a
        href="#"
        :class="{ active: i === pagination.currentPage }"
        @click.prevent="setPage(i)"
        >{{ i }}</a
      >
    </li>
    <li v-if="pagination.currentPage < totalPages">
      <a href="#" @click.prevent="next()"
        ><i class="lni lni-arrow-right"></i
      ></a>
    </li>
  </ul>
</template>

<script>
import { computed, defineComponent, watch } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Pagination",
  setup() {
    const store = useStore();

    const totalPages = computed(() => {
      return Math.ceil(
        store.getters.productsByTypeCategory.length /
          store.state.pagination.itemsPerPage
      );
    });

    const pagination = computed(() => store.state.pagination);

    watch(
      () => store.getters.productsByTypeCategory,
      () => {
        resetPage();
      }
    );

    function setPage(page) {
      store.commit("setCurrentPage", page);
    }

    function resetPage() {
      store.commit("setCurrentPage", 1);
    }

    function previous() {
      const page = Math.max(0, store.state.pagination.currentPage - 1);
      store.commit("setCurrentPage", page);
    }

    function next() {
      const page = Math.min(
        totalPages.value,
        store.state.pagination.currentPage + 1
      );
      store.commit("setCurrentPage", page);
    }

    return {
      next,
      pagination,
      previous,
      setPage,
      totalPages,
    };
  },
});
</script>

<style lang="scss" scoped>
#pagination {
  margin: 0;
  text-align: center;
  padding-bottom: 40px;

  li {
    display: inline;

    a {
      font-size: 1.3em;
      font-weight: bolder;
      display: inline-block;
      text-decoration: none;
      padding: 0 5px 0 10px;
      color: rgba(0, 0, 0, 0.55);
      transition: color 0.25s ease-out;

      &:hover {
        color: $primary-color !important;
      }

      &.active {
        color: $primary-color !important;
      }
    }
  }
}
</style>
