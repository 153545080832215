let resolveInitPromise;
let rejectInitPromise;

export const GoogleMapLoader = {
  initialized:
    typeof window.google === "object" && typeof window.google.maps === "object",
  // This promise handles the initialization
  // status of the Google Maps script.
  initPromise: new Promise((resolve, reject) => {
    resolveInitPromise = resolve;
    rejectInitPromise = reject;
  }),
  init(apiKey, callbackName, language, region) {
    // If Google Maps already is initialized
    // the `initPromise` should get resolved
    // eventually.
    if (this.initialized) {
      return this.initPromise;
    }

    this.initialized = true;

    // The callback function is called by
    // the Google Maps script if it is
    // successfully loaded.
    window[callbackName] = () => resolveInitPromise(window.google);

    // We inject a new script tag into
    // the `<head>` of our HTML to load
    // the Google Maps script.
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=${callbackName}&language=${language}&region=${region}`;
    script.onerror = rejectInitPromise;
    document.querySelector("head")?.appendChild(script);

    return this.initPromise;
  },
};
