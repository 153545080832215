<template>
  <div class="mapouter">
    <div id="g-map" ref="mapEl"></div>
  </div>
</template>

<script setup>
import { onMounted, ref, shallowRef } from "vue";
import { GoogleMapLoader } from "../utils/GoogleMapsLoader";
import { useI18n } from "vue-i18n";
import { mapOptions } from "../utils/gmapOptions";

const composer = useI18n({ useScope: "global" });

let map = shallowRef();
const mapEl = ref();
const mapInitialized = ref(false);

onMounted(async () => {
  try {
    await GoogleMapLoader.init(
      process.env.VUE_APP_GMAP_API_KEY,
      "gmapsCallback",
      composer.locale.value,
      "MD"
    );

    map.value = new window.google.maps.Map(mapEl.value, mapOptions);
    mapInitialized.value = true;

    new window.google.maps.Marker({
      position: mapOptions.center,
      map: map.value,
      title: "Brillio",
    });
  } catch (error) {
    console.error(error);
  }
});

defineExpose({
  mapEl,
});
</script>

<style lang="scss" scoped>
.mapouter {
  position: relative;
  text-align: right;
  height: 300px;
  width: 100% !important;
}

#g-map {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
}
</style>
