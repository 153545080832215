<!--https://jewelry-site.s3.amazonaws.com/invalid-account-Y_bxfTa_iUA-unsplash.jpg-->
<!--https://jewelry-site.s3.amazonaws.com/invalid-account-wh_rZqPiCuw-unsplash.jpg-->
<template>
  <div class="card">
    <div class="card-img-top ratio ratio-4x3 rounded-2 position-relative">
      <router-link :to="`/product/${id}`">
        <img :src="imgUrl" :alt="title" />
        <i class="lni lni-diamond-alt card-img-icon"></i>
      </router-link>
    </div>
    <div class="card-body">
      <p class="card-title p-secondary">
        {{ title }}
      </p>
      <div class="price-wrapper">
        <p class="card-text important-font d-inline-block">
          {{ discountPrice }} {{ $t("lei") }}
        </p>
        <p v-if="showDiscount" class="card-text d-inline-block crossed-price">
          {{ price }} {{ $t("lei") }}
        </p>
      </div>
      <div class="card-button-area text-center">
        <custom-button
          tag="a"
          :label="$t('view-btn')"
          :to="`/product/${id}`"
          class="text-center"
        />
      </div>
    </div>
  </div>
</template>

<script>
import slugify from "slugify";
import CustomButton from "./CustomButton";

export default {
  name: "CardElement",
  components: { CustomButton },
  props: {
    id: {
      type: Number,
      required: true,
    },
    title_ru: {
      type: String,
      required: true,
    },
    title_ro: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    discountPrice: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    title() {
      return this.$i18n.locale === "ro" ? this.title_ro : this.title_ru;
    },
    imgUrl() {
      return `${process.env.VUE_APP_S3_ASSETS_URL}/${this.img}/${slugify(
        this.title_ro
      )}.jpg?width=700&quality=80`;
    },
    showDiscount() {
      return (
        this.discountPrice &&
        this.discountPrice !== -1 &&
        this.discountPrice !== this.price
      );
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transform: translate3d(0, 0, 0) scale(1);
  transition: transform 0.25s ease-out, filter 0.25s ease-out;
  filter: brightness(100%);

  &:hover {
    transform: scale(1.07);
    filter: brightness(50%);
  }
}

.card-img-icon {
  pointer-events: none;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 3rem;
  color: white;
  opacity: 0;
  transform: translate3d(-50%, -50%, 0);
  transition: opacity 0.25s ease-out, font-size 0.25s ease-out;
}

.card-img-top {
  &:hover {
    .card-img-icon {
      opacity: 80;
      font-size: 2.5rem;
    }
  }
}
</style>
