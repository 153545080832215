import ro from "./ro.json";

export default {
  ro: {
    name: "RO",
    messages: ro,
  },
  ru: {
    name: "RU",
    load: () => {
      return import("./ru.json");
    },
  },
};
