<template>
  <div class="wrapper">
    <main-header />
    <main-nav />
    <back-to-top />
    <slot />

    <div style="background-color: #f5f5f5">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-8 col-lg-10">
            <google-map />
          </div>
          <div class="col-12 col-sm-4 col-lg-2">
            <address-info />
          </div>
        </div>
      </div>
    </div>
    <main-footer />
  </div>
</template>

<script>
import MainHeader from "../components/MainHeader";
import BackToTop from "../components/BackToTop";
import MainFooter from "../components/MainFooter";
import AddressInfo from "../components/AddressInfo";
import GoogleMap from "../components/GoogleMap.vue";
import MainNav from "../components/MainNav.vue";

export default {
  name: "BaseLayout",
  components: {
    MainNav,
    GoogleMap,
    AddressInfo,
    MainFooter,
    BackToTop,
    MainHeader,
  },
};
</script>

<style scoped></style>
