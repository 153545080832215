<template>
  <language-switcher
    v-slot="{ links }"
    tag="ul"
    active-class="link-active"
    class="list-inline"
  >
    <li
      class="list-inline-item me-0"
      v-for="link in links"
      :key="link.langIndex"
    >
      <a
        :href="link.url"
        :class="link.activeClass"
        @click.prevent="$router.push(link.url)"
        ><custom-button :label="link.langIndex.toUpperCase()"
      /></a>
    </li>
  </language-switcher>
</template>

<script>
import CustomButton from "./CustomButton";

export default {
  name: "ChangeLang",
  components: { CustomButton },
};
</script>

<style lang="scss" scoped>
li {
  a {
    color: black;
  }

  .link-active {
    color: $primary-color;
  }
}
</style>
