<template>
  <ModalsContainer />
  <router-view />
</template>

<script>
import { useStore } from "vuex";
import { useTitle } from "@vueuse/core";
import { ModalsContainer } from "vue-final-modal";

export default {
  components: { ModalsContainer },
  setup() {
    const store = useStore();

    store.dispatch("fetchCategories");
    store.dispatch("fetchSiteData");

    useTitle("Brillio");
  },
};
</script>

<style lang="scss"></style>
