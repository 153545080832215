import { createWebHistory } from "vue-router";
import { createLangRouter } from "../plugins/vue-lang-router";
import Home from "../views/Home.vue";

import translations from "../lang/translations";
// import localizedURLs from "../lang/localized-urls";
// import datetimeFormats from "../lang/datetimeFormats";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    alias: "/home",
  },
  {
    path: "/product/:id",
    name: "product",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "product" */ "../views/Product"),
  },
  {
    path: "/slideshow",
    name: "slideshow",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "slideshow" */ "../views/Slideshow"),
  },
];

// Initiate router
const langRouterOptions = {
  defaultLanguage: "ro",
  translations,
  // localizedURLs,
  //i18nOptions: {
  //  datetimeFormats,
  //},
};
const routerOptions = {
  routes,
  history: createWebHistory(process.env.BASE_URL),
};

const router = createLangRouter(langRouterOptions, routerOptions);

export default router;
