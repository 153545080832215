import { createStore } from "vuex";
import {
  getCategories,
  getProducts,
  getProductType,
  getSimilarProducts,
  getSiteData,
} from "../services/brillioApiService";

export default createStore({
  state: {
    products: [],
    similarProducts: [],
    categories: [],
    productTypes: [],
    activeCategory: null,
    pagination: {
      currentPage: 1,
      itemsPerPage: 24,
    },
    siteData: {},
  },
  mutations: {
    setActiveCategory(state, categoryId) {
      state.activeCategory = categoryId;
    },
    setProductTypes(state, productTypes) {
      state.productTypes = productTypes;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
  },
  getters: {
    productsByCategory(state) {
      if (!state.activeCategory) {
        return state.products;
      } else {
        return state.products.filter((product) => {
          return product.category.some((category) => {
            return category.Product_Category_id.id === state.activeCategory;
          });
        });
      }
    },
    availableProductTypeIds(state, getters) {
      const ids = new Set();
      getters.productsByCategory.forEach((product) => {
        product.product_type.forEach((productType) => {
          ids.add(productType.Product_Type_id);
        });
      });

      return ids;
    },
    activeFilters(state) {
      return state.productTypes
        .filter((item) => item.active)
        .map((item) => item.id);
    },
    productsByTypeCategory(state, getters) {
      if (getters.activeFilters.length === 0) {
        return getters.productsByCategory;
      } else {
        return getters.productsByCategory.filter((product) => {
          return product.product_type.some((productType) =>
            getters.activeFilters.includes(productType.Product_Type_id)
          );
        });
      }
    },
    paginatedProducts(state, getters) {
      const firstIndex =
        (state.pagination.currentPage - 1) * state.pagination.itemsPerPage;
      const lastIndex = firstIndex + state.pagination.itemsPerPage;
      return getters.productsByTypeCategory.slice(firstIndex, lastIndex);
    },
  },
  actions: {
    async fetchProducts(store) {
      try {
        const res = await getProducts();
        store.state.products = res.data.map((item) => {
          return {
            active: false,
            ...item,
          };
        });
      } catch (err) {
        console.error(err);
      }
    },
    async fetchCategories(store) {
      try {
        const res = await getCategories();
        store.state.categories = res.data;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchProductTypes(store) {
      try {
        const res = await getProductType();
        store.state.productTypes = res.data.map((item) => {
          return {
            active: false,
            ...item,
          };
        });
      } catch (err) {
        console.error(err);
      }
    },
    async fetchSiteData(store) {
      try {
        store.state.siteData = await getSiteData();
      } catch (err) {
        console.error(err);
      }
    },
    // Return a promise that resolves to an array of similar products
    // or an empty array if there is an error
    async fetchSimilarProducts(store, { categoryId, productId }) {
      try {
        const res = await getSimilarProducts(productId, categoryId);
        store.state.similarProducts = res.data || [];
      } catch (err) {
        console.error(err);
        store.state.similarProducts = [];
      }
    },
  },
  modules: {},
});
