<template>
  <div v-if="availableFilters.length > 0" class="filters-wrapper pt-4">
    <p
      class="d-flex justify-content-between align-items-center p-secondary text-center"
      :class="{ 'toggle-filters': !isMediumScreen }"
      @click="toggleFilters"
    >
      <span class="text-nowrap">{{ $t("jewelry-type") }}</span
      ><button v-if="!isMediumScreen" class="unstyled-btn">
        <i class="lni" :class="[showFilters ? 'lni-close' : 'lni-search']"></i>
      </button>
    </p>

    <slide-up-down v-model="showFilters" :responsive="true" :duration="400">
      <ul class="filters-elements">
        <li v-for="filter in availableFilters" :key="filter.id">
          <custom-checkbox
            v-model="filter.active"
            :label="filter[`title_${$i18n.locale}`]"
          />
        </li>
      </ul>
    </slide-up-down>
  </div>
</template>

<script>
import CustomCheckbox from "./CustomCheckbox";
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import { useMediaQuery } from "@vueuse/core";
import SlideUpDown from "vue3-slide-up-down";

export default defineComponent({
  name: "Filters",
  components: {
    CustomCheckbox,
    SlideUpDown,
  },
  setup() {
    const store = useStore();

    // vue use media query to show/hide filters
    const isMediumScreen = useMediaQuery("(min-width: 768px)");
    const showFilters = ref(isMediumScreen.value);
    watch(isMediumScreen, (value) => {
      showFilters.value = value;
    });

    const filters = computed({
      get() {
        return store.state.productTypes;
      },
      set(value) {
        store.commit("setProductTypes", value);
      },
    });

    const toggleFilters = () => {
      if (isMediumScreen.value) return;

      showFilters.value = !showFilters.value;
    };

    const availableFilters = computed(() => {
      return filters.value.filter((item) =>
        store.getters.availableProductTypeIds.has(item.id)
      );
    });

    store.dispatch("fetchProductTypes");

    return {
      availableFilters,
      filters,
      isMediumScreen,
      showFilters,
      toggleFilters,
    };
  },
});
</script>

<style lang="scss" scoped>
.filters-wrapper {
  max-width: 190px;
  position: sticky;
  top: 30px;
  padding-right: 30px;

  .toggle-filters {
    border-radius: 8px;
    background-color: $light-violet;
    color: white;
    padding: 5px 15px;
    cursor: pointer;

    &:hover {
      background-color: $primary-color;
      transition: background-color 0.5s;
    }

    i {
      color: white;
    }
  }

  .filters-elements {
    list-style-type: none;
    padding: 0;

    li {
      padding-bottom: 10px;

      &.disabled {
        display: none;
      }
    }
  }
}
</style>
